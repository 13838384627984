import { DataGrid, GridColDef, GridRowSelectionModel, GridRowsProp } from "@mui/x-data-grid"
import { Asset, User } from "../AuthenticatedHome"
import { useEffect, useState } from "react"
import Status from "../../Components/Status"
import { Button, Form } from "react-bootstrap"
import Modal from 'react-bootstrap/Modal';
import ReactDatePicker from "react-datepicker"
import { v4 as uuidv4 } from 'uuid';
import moment from "moment"
import ReportContainer from "../../Components/ReportContainer"
import * as Papa from 'papaparse'; // For generating CSV
export interface ReportRequest {
    id: string,
    user: User,
    department: string,
    content: string,
    title: string,
    requestedDate: string,
    dueDate: string,
    schedule: string,
    status: string,
    RequestApproval: string,
    ReportApproval: string,
    data: any[]
}

interface Props {
    user: User
    reportRequests: ReportRequest[]
    admins: User[]
    assets: Asset[]
    setReportData: (data: any[], report: ReportRequest) => void
    createRequest: (request: ReportRequest) => void
    updateStatus: (type: "iainStatus" | "requestStatus" | "reportStatus", status: string, report: ReportRequest | undefined) => void
}

const Reports = ({ setReportData, assets, admins, user, createRequest, reportRequests, updateStatus }: Props) => {
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [selectedReport, setSelectedReport] = useState<ReportRequest>()
    const [viewing, setViewing] = useState<boolean>(false)
    const isSuperAdmin = ["5b6600c9-e466-4f3f-8538-76da45fe82a8", "761b869d-2f90-4be7-91a6-3aaee6df11cd", "9a5a2200-baab-4afd-9ccc-3507cdae153d"].find((e) => e === user.id);
    const [title, setTitle] = useState<string>("");
    const [department, setDepartment] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [dueDate, setDueDate] = useState<Date>();
    const [schedule, setSchedule] = useState<string>("");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns: GridColDef[] = [
        { field: 'title', headerName: 'TITLE', flex: 2 },
        { field: 'user', headerName: 'USER', flex: 1 },
        { field: 'department', headerName: 'DEPARTMENT', flex: 1 },
        { field: 'requestedDate', headerName: 'REQUESTED', flex: 1 },
        { field: 'dueDate', headerName: 'DUE', flex: 1 },
        {
            field: 'status', headerName: 'STATUS', flex: 1,
            sortComparator: (v1, v2) => v1.localeCompare(v2),
            renderCell: (params: any) => (
                <Status className="w-100" text={params.value} />
            ),
            valueGetter: (params) => params.value.props.text
        },
    ];

    const rows: GridRowsProp = reportRequests
        .filter((request) => {
            if (admins.find((e) => e.id === user.id)) {
                return request;
            } else if (request.user.id === user.id) {
                return request;
            }
        })
        .sort((a, b) => moment(b.requestedDate).diff(moment(a.requestedDate))) // Sort by requestedDate (descending)
        .map((request, index) => {
            return {
                id: request.id,
                title: request.title,
                user: request.user.name,
                department: request.department,
                requestedDate: moment(request.requestedDate).format("DD/MM/YYYY"),
                dueDate: moment(request.dueDate).format("DD/MM/YYYY"),
                status: <Status className="w-100" text={request.status === "Send For Approval" ? "In Progress" : request.status} />,
            };
        });

    const buildRequest = () => {
        const id = uuidv4()
        const request: ReportRequest = {
            department: department,
            content: content,
            title: title,
            dueDate: dueDate?.toDateString() || "Unknown",
            requestedDate: new Date().toDateString(),
            schedule: schedule,
            status: "Queued",
            user: user,
            id: id,
            ReportApproval: "Awaiting Approval",
            RequestApproval: "Awaiting Approval",
            data: []
        }
        createRequest(request)
        setDepartment("")
        setContent("")
        setDueDate(undefined)
        setSchedule("")
        handleClose()
    }
    useEffect(() => {
        reportRequests.map((item) => {
            if (selectionModel.length > 0 && item.id === selectionModel[0].toString()) {
                setSelectedReport(item)
            }
        })
    }, [selectionModel])

    const isWithinCurrentFinancialYear = (dateStr: string): boolean => {
        const date = new Date(dateStr);
        if (isNaN(date.getTime())) {
            console.log(`Invalid date: ${dateStr}`);
            return false; // If date is invalid, return false
        }

        const today = new Date();
        const currentYear = today.getFullYear();

        // Calculate the current financial year based on the current date
        const financialYearStart = new Date(today.getMonth() >= 6 ? `${currentYear}-07-01` : `${currentYear - 1}-07-01`);
        const financialYearEnd = new Date(today.getMonth() >= 6 ? `${currentYear + 1}-06-30` : `${currentYear}-06-30`);

        console.log(`Date: ${date.toISOString()}, Financial Year Start: ${financialYearStart.toISOString()}, Financial Year End: ${financialYearEnd.toISOString()}`);

        // Ensure the date falls within the current financial year range
        return date >= financialYearStart && date <= financialYearEnd;
    };

    const generateCSVForCurrentFinancialYear = (assets: Asset[]) => {
        // Filter assets based on the financial year and presence of datePurchased
        const filteredAssets = assets.filter(asset =>
            asset.datePurchased && isWithinCurrentFinancialYear(asset.datePurchased)
        );

        console.log(filteredAssets)

        // Map the filtered assets to the desired CSV format
        const csvData = filteredAssets.map(asset => ({
            id: asset.id,
            productTitle: asset.productTitle,
            brand: asset.brand,
            category: asset.category,
            company: asset.company,
            price: asset.price
        }));

        // Convert to CSV format using PapaParse
        const csv = Papa.unparse(csvData);

        // Create a Blob from the CSV string
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        // Create a link and trigger a download
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'assets_financial_year.csv');
        document.body.appendChild(link);
        link.click();

        // Clean up the URL object
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        console.log('CSV file generated and downloaded successfully!');
    };

    return (
        <ReportContainer setReportData={(data) => selectedReport && setReportData(data, selectedReport)} statusChange={(a) => updateStatus("iainStatus", a, selectedReport)} approvalRequestChange={(a) => updateStatus("requestStatus", a, selectedReport)} approvalReportChange={(e) => updateStatus("reportStatus", e, selectedReport)} reportRequest={selectedReport} admins={admins} user={user} viewRequest={viewing} closeRequest={() => setViewing(false)}>
            <>
                {!viewing &&
                    <div className="w-100 d-flex justify-content-end">
                        {isSuperAdmin &&
                            <Button onClick={() => generateCSVForCurrentFinancialYear(assets)} className="mb-2 mr-2 buttonES">Generate IT Spend Report</Button>
                        }
                        <Button onClick={handleShow} className="mb-2 buttonES">Request Report</Button>
                    </div>
                }
                <Modal data-bs-theme="dark" size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Request Report</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control value={title} onChange={(e) => setTitle(e.currentTarget.value)} placeholder="Enter title..." />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description:</Form.Label>
                            <Form.Control value={content} onChange={(e) => setContent(e.currentTarget.value)} placeholder="Enter description..." as="textarea" rows={4} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Department:</Form.Label>
                            <Form.Select value={department} onChange={(e) => setDepartment(e.currentTarget.value)} aria-label="Default select example">
                                <option>Select department...</option>
                                <option value="Contracts - Office">Contracts - Office</option>
                                <option value="Contracts - Site">Contracts - Site</option>
                                <option value="Design">Design</option>
                                <option value="Directors">Directors</option>
                                <option value="Finance & Warehouse">Finance & Warehouse</option>
                                <option value="Process">Process</option>
                                <option value="Remedials - FPS">Remedials - FPS</option>
                                <option value="Remedials - LPS">Remedials - LPS</option>
                                <option value="Rope Access">Rope Access</option>
                                <option value="Sales">Sales</option>
                                <option value="Test & Inspection Operations">Test & Inspection Operations</option>
                                <option value="Test & Inspection Sales">Test & Inspection Sales</option>
                                <option value="Test & Inspection Site">Test & Inspection Site</option>
                                <option value="Training & PPE">Training & PPE</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Due Date:</Form.Label>
                            <ReactDatePicker wrapperClassName="form-control" onChange={(date: Date) => setDueDate(date)} locale="en-GB" className={"form-control w-100"} calendarClassName='w-100' selected={dueDate} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Report Schedule:</Form.Label>
                            <Form.Select value={schedule} onChange={(e) => setSchedule(e.currentTarget.value)} aria-label="Default select example">
                                <option>Select report schedule...</option>
                                <option value="Just once">Just once</option>
                                <option value="Monthly">Monthly</option>
                                <option value="Quarterly">Quarterly</option>
                                <option value="Yearly">Yearly</option>
                            </Form.Select>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => {
                            buildRequest()
                        }} className="buttonES">Submit Request</Button>
                    </Modal.Footer>
                </Modal>

                <DataGrid
                    rows={rows}
                    columns={columns}
                    onRowSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    rowSelectionModel={selectionModel}
                    onRowClick={() => setViewing(true)}
                />
            </>
        </ReportContainer>
    )
}

export default Reports