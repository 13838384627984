import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { callMsGraph } from "../graph";
import { loginRequest } from "../authConfig";
import { initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getFirestore, getDocs, collection, setDoc, doc, addDoc, getDoc } from "firebase/firestore";
import Page from "../Components/Page";
import MyTickets from "./SubPages/MyTickets";
import Completed from "./SubPages/Completed";
import AllTickets from "./SubPages/AllTickets";
import RaiseTicket from "./SubPages/RaiseTicket";
import AssetRegister from "./SubPages/AssetRegister";
import Dashboard from "./SubPages/Dashboard";
import JSConfetti from 'js-confetti'
import { renderToString } from 'react-dom/server'
import yay from "../Images/Yay.mp3"
import EmailTemplate from "../Components/EmailTemplate";
import { EditorState, RawDraftContentState } from "draft-js";
import { Bounce, ToastContainer, toast } from "react-toastify";
import Developer, { Project, ProjectTicket } from "./SubPages/Developer";
import axios from "axios";
import Reports, { ReportRequest } from "./SubPages/Reports";
import { SOW } from "../Components/ProjectContainer";
export interface graphAPIData {
    businessPhone: []
    displayName: string
    givenName: string
    id: string;
    jobTitle: string
    mail: string
    surname: string
}

export interface User {
    name: string;
    id: string;
    email: string
}

export interface SubTask {
    id: string;
    description: string;
    status: string;
    assigned: User;
    due: string;
}

export interface ticket {
    ticketId: number;
    firstNoticed?: string;
    subject: string;
    description: string;
    status: string;
    file?: string;
    assigned?: User;
    raisedBy: User;
    location?: string;
    device?: string;
    type?: string;
    scheduledDate?: Date;
    priority: string;
    dateRaised: string;
    comments?: {
        comment: string;
        user: User;
        date: string;
    }[];
    subTasks?: SubTask[];
    timeTaken?: string;
    department?: string;
}

export interface KnowledgebaseItem {
    text: string;
    lastUser: User;
    date: string;
    ticketId: number;
}

export interface NewKnowledge {
    id: string,
    issue: string,
    type: string,
    device: string,
    loggedBy: string,
    description: string,
    screenshot?: string
}

export interface Email {
    to: string[]
    message: {
        subject: string
        text?: string
        html?: string
    }
}

export interface Asset {
    assigned: {
        ticket: string,
        user: {
            email: string,
            id: string,
            name: string
        }
    },
    brand: string,
    category: string,
    history: {
        date: string,
        status: string,
        user: {
            email: string,
            id: string,
            name: string
        }
    }[],
    id: string,
    imageId: string,
    imei: string,
    mobile: string,
    price: string,
    productTitle: string,
    serialNumber: string,
    source: string,
    existing?: boolean,
    datePurchased?: string,
    dateAdded?: string,
    status: string,
    company?: string,
}

const AuthenticatedHome = () => {
    const { instance, accounts } = useMsal();
    const [graphData, setGraphData] = useState<graphAPIData>();
    const [active, setActive] = useState<string>("my tickets")
    const [activeSub, setActiveSub] = useState<string>("")
    const [allData, setAllData] = useState<ticket[]>([])
    const [allProjects, setAllProjects] = useState<Project[]>([])
    const [admins, setAdmins] = useState<User[]>([])
    const [globalAlert, setGlobalAlert] = useState<string>("")
    const [assets, setAssets] = useState<Asset[]>([])
    const [viewSub, setViewSub] = useState<boolean>(false)
    const [knowledgebase, setKnowledgebase] = useState<NewKnowledge[]>([])
    const [allReports, setAllReports] = useState<ReportRequest[]>([])

    const firebaseConfig = {
        apiKey: "AIzaSyCMhQQLe3kiz-53H8iVYDojsCw5yZJR7dg",
        authDomain: "it-support-f8802.firebaseapp.com",
        projectId: "it-support-f8802",
        storageBucket: "it-support-f8802.appspot.com",
        messagingSenderId: "1081930761257",
        appId: "1:1081930761257:web:e86eafc0dee00e53804838"
    };

    const app = initializeApp(firebaseConfig);

    const storage = getStorage(app);
    const db = getFirestore(app);

    const getProfile = async () => {
        await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then(async (response: any) => {
            await callMsGraph(response.accessToken).then(response => setGraphData(response));
        });
    }

    const getData = async () => {
        const temp: ticket[] = []
        const adminTemp: User[] = []
        const knowledgebaseTemp: NewKnowledge[] = []
        const querySnapshot = await getDocs(collection(db, "tickets"));
        querySnapshot.forEach((doc) => {
            //@ts-ignore
            temp.push(doc.data())
        });
        const adminQuerySnapshot = await getDocs(collection(db, "admins"));
        adminQuerySnapshot.forEach((doc) => {
            //@ts-ignore
            adminTemp.push(doc.data())
        });

        const knowledgebaseQuerySnapshot = await getDocs(collection(db, "knowledgebaseNew"));
        knowledgebaseQuerySnapshot.forEach((doc) => {
            //@ts-ignore
            knowledgebaseTemp.push(doc.data())
        });

        const globalAlertSnapshot = await getDoc(doc(db, "global", "globalAlert"));
        //@ts-ignore
        setGlobalAlert(globalAlertSnapshot.data().text)
        temp.sort((a, b) => b.ticketId - a.ticketId)
        setAllData(temp)
        setAdmins(adminTemp)
        setKnowledgebase(knowledgebaseTemp)
    }

    const sendAddTicketEmail = async (ticket: ticket) => {
        const temp: Email = {
            to: ["itsupport@eurosafesolutions.co.uk", ticket.raisedBy.email],
            message: {
                subject: `Ticket #${ticket.ticketId} has been raised`,
                html: renderToString(<EmailTemplate content={`<b>Ticket #${ticket.ticketId} has been raised with a priority of "${ticket.priority}"</b><b>${ticket.subject}</b>${ticket.description}<p>This ticket was raised by ${ticket.raisedBy.name} who is contactable at ${ticket.raisedBy.email}</p>`} />),
            }
        }
        await addDoc(collection(db, "email"), temp)
    }

    const sendNewUserEmail = async (ticket: ticket) => {
        const temp: Email = {
            to: ["support@1101.co.uk"],
            message: {
                subject: `A new starter ticket has been raised`,
                html: renderToString(<EmailTemplate content={`<b>A new starter ticket has been raised</b><b>${ticket.subject}</b>${ticket.description}<p>This ticket was raised by ${ticket.raisedBy.name} who is contactable at ${ticket.raisedBy.email}</p><b>You do not need to do anything with this ticket it is just an alert<b/>`} />),
            }
        }
        await addDoc(collection(db, "email"), temp)
    }

    const sendLeaveUserEmail = async (ticket: ticket) => {
        const temp: Email = {
            to: ["support@1101.co.uk"],
            message: {
                subject: `Eurosafe has a leaver`,
                html: renderToString(<EmailTemplate content={`<b>A new leaver ticket has been raised</b><b>${ticket.subject}</b>${ticket.description}<p>This ticket was raised by ${ticket.raisedBy.name} who is contactable at ${ticket.raisedBy.email}</p><b>You do not need to do anything with this ticket it is just an alert<b/>`} />),
            }
        }
        await addDoc(collection(db, "email"), temp)
    }

    const addTicket = async (ticket: ticket) => {
        await setDoc(doc(db, "tickets", ticket.ticketId.toString()), ticket);
        sendAddTicketEmail(ticket)
        if (ticket.subTasks && ticket.subTasks?.length > 0) {
            ticket.subTasks?.map((item) => {
                if (item.description === "Assign licences on O365") {
                    sendNewUserEmail(ticket)
                }
                if (item.description === "Immediately block sign in (if applicable)") {
                    sendLeaveUserEmail(ticket)
                }
            })
        }
        getData()
    }

    const onSubChange = async (ticket: ticket) => {
        await setDoc(doc(db, "tickets", ticket.ticketId.toString()), ticket);
    }

    const addComment = async (ticket: ticket) => {
        await setDoc(doc(db, "tickets", ticket.ticketId.toString()), ticket);
        sendCommentEmail(ticket)
    }

    const sendCommentEmail = async (ticket: ticket) => {
        if (ticket.assigned && ticket.comments) {
            const temp: Email = {
                to: [ticket.assigned.email, ticket.raisedBy.email],
                message: {
                    subject: `New comment added to #${ticket.ticketId}`,
                    html: renderToString(<EmailTemplate itResponder={false} content={`<b>A new comment has been added to #${ticket.ticketId} - ${ticket.subject}</b><p><b>${ticket.comments[ticket.comments?.length - 1].user.name}:</b></p>${ticket.comments[ticket.comments?.length - 1].comment}`} />)
                }
            }
            await addDoc(collection(db, "email"), temp)
        }
    }

    const onAssignedChange = async (value: User, ticket: ticket) => {
        const temp: ticket = ticket
        temp.assigned = value
        await setDoc(doc(db, "tickets", ticket.ticketId.toString()), temp);
        sendAssignedEmail(temp)
    }

    const sendAssignedEmail = async (ticket: ticket) => {
        if (ticket.assigned) {
            const temp: Email = {
                to: [ticket.assigned.email],
                message: {
                    subject: `You have been assigned to #${ticket.ticketId}`,
                    html: renderToString(<EmailTemplate content={`<p>Hello ${ticket.assigned.name},</p><b>You have been assigned to ticket #${ticket.ticketId}</b><p><b>${ticket.subject}</b></p>${ticket.description}<p>This ticket was raised by ${ticket.raisedBy.name} who is contactable at ${ticket.raisedBy.email}</p>`} />)
                }
            }
            await addDoc(collection(db, "email"), temp)
        }
    }

    const onStatusPriorityChange = async (type: string, value: string, ticket: ticket) => {
        const temp: ticket = ticket
        if (type === "status") {
            if (value === "Completed") {
                new Audio(yay).play()
                const jsConfetti = new JSConfetti()
                setTimeout(function () {
                    jsConfetti.addConfetti({
                        emojis: ['🌈', '🦄', '✨'],
                        emojiSize: 100,
                        confettiNumber: 100,
                    })
                    toast.success('Click here to view knowledgebase', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        onClick: () => {
                            setActive("dashboard")
                            setActiveSub("Knowledgebase")
                            setViewSub(true)
                        },
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }, 1000);

            } else {
                temp.department = ""
                temp.timeTaken = ""
            }
            temp.status = value
            if (temp.status === "No Response") {
                sendNoResponseStatusEmail(temp)
            } else {
                sendStatusEmail(temp)
            }
        }
        if (type === "priority") {
            temp.priority = value
            sendPriorityEmail(temp)
        }
        await setDoc(doc(db, "tickets", ticket.ticketId.toString()), temp);
    }

    const sendStatusEmail = async (ticket: ticket) => {
        const temp: Email = {
            to: [ticket.raisedBy.email],
            message: {
                subject: `#${ticket.ticketId} status changed`,
                html: renderToString(<EmailTemplate content={`<p>Hello ${ticket.raisedBy.name},</p><b>The status of your ticket regarding ${ticket.subject} has been changed to "${ticket.status}"</b>`} />),
            }
        }
        await addDoc(collection(db, "email"), temp)
    }

    const sendNoResponseStatusEmail = async (ticket: ticket) => {
        const temp: Email = {
            to: [ticket.raisedBy.email],
            message: {
                subject: `#${ticket.ticketId} status changed`,
                html: renderToString(<EmailTemplate content={`<p>Hello ${ticket.raisedBy.name},</p><b>The status of your ticket regarding ${ticket.subject} has been closed due to no response"</b>`} />),
            }
        }
        await addDoc(collection(db, "email"), temp)
    }

    const sendPriorityEmail = async (ticket: ticket) => {
        const temp: Email = {
            to: [ticket.raisedBy.email],
            message: {
                subject: `#${ticket.ticketId} priority changed`,
                html: renderToString(<EmailTemplate content={`<p>Hello ${ticket.raisedBy.name},</p><b>The priotity of your ticket regarding ${ticket.subject} has been changed to "${ticket.priority}"</b>`} />),
            }
        }
        await addDoc(collection(db, "email"), temp)
    }

    const getAssetRegister = async () => {
        const temp: Asset[] = []
        const querySnapshot = await getDocs(collection(db, "assets"));
        querySnapshot.forEach((doc) => {
            //@ts-ignore
            temp.push(doc.data())
        });
        const filtered = temp.sort((a, b) => {
            const na = parseInt(a.id.split("-")[2])
            const nb = parseInt(b.id.split("-")[2])
            if (na < nb) return 1;
            if (na > nb) return -1;
            return 0;
        })
        setAssets(filtered)
    }

    const getProjects = async () => {
        const temp: Project[] = []
        const querySnapshot = await getDocs(collection(db, "projects"));
        querySnapshot.forEach((doc) => {
            //@ts-ignore
            temp.push(doc.data())
        });
        setAllProjects(temp)
    }

    const setAssetRegister = async (asset: Asset) => {
        await setDoc(doc(db, "assets", asset.id), asset).then(() => getAssetRegister());
    }

    const duplicateAsset = async (asset: Asset) => {
        const temp = asset
        temp.id = "ESL-IT-" + (assets.length + 1).toString()
        await setDoc(doc(db, "assets", temp.id), temp).then(() => getAssetRegister());
    }

    const setNewProjectTicket = async (ticket: ProjectTicket, project: Project) => {
        const temp = project
        //@ts-ignore
        temp.tickets[ticket.prefix].push(ticket)
        sendDevEmail(ticket, project)
        await setDoc(doc(db, "projects", project.id), temp).then(() => getProjects());
    }

    const sendDevEmail = async (ticket: ProjectTicket, project: Project) => {
        if (ticket.user) {
            const temp: Email = {
                to: [ticket.user.email],
                message: {
                    subject: `${ticket.id} has been raised in ${project.projectName}`,
                    html: renderToString(<EmailTemplate content={`<p>Hello,</p><b>${ticket.title} has been raised in ${ticket.prefix.toLowerCase()}</b>`} />),
                }
            }
            await addDoc(collection(db, "email"), temp)
        }
    }


    const setProject = async (project: Project) => {

        const docRef = doc(db, "projects", project.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            toast.error('Project ID already exists. Please choose a different ID.', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                onClick: () => {
                    setActive("dashboard")
                    setActiveSub("Knowledgebase")
                    setViewSub(true)
                },
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {
            await setDoc(doc(db, "projects", project.id), project).then(() => getProjects());
        }
    }
    const setChangeProject = async (project: Project) => {
        await setDoc(doc(db, "projects", project.id), project).then(() => getProjects());
    }

    const addKnowledge = async (item: NewKnowledge, file: File | null) => {
        await setDoc(doc(db, "knowledgebaseNew", item.id), item);
        if (file) {
            await uploadBytes(ref(storage, `/KnowledgebaseScreenshots/${item.id}-${file.name}`), file)
        }
        getData()
    }

    const uploadFile = async (file: File) => {
        if (file) {
            uploadBytes(ref(storage, `/Files/${file.name}`), file)
        }
    }

    const getReports = async () => {
        const temp: ReportRequest[] = []
        const querySnapshot = await getDocs(collection(db, "reports"));
        querySnapshot.forEach((doc) => {
            //@ts-ignore
            temp.push(doc.data())
        });
        setAllReports(temp)
    }

    const sendRepCreateEmail = async (report: ReportRequest) => {
        if (report) {
            const temp: Email = {
                to: ["cluck@eurosafeuk.com"],
                message: {
                    subject: `Report Approval Request`,
                    html: renderToString(<EmailTemplate content={`<p>Hello,</p><p>A new report request has been raised and requires your approval</p><p>${report.content}</p>`} />),
                }
            }
            await addDoc(collection(db, "email"), temp)
        }
    }

    const sendApprovedStatusEmail = async (report: ReportRequest, status: string, to: string[]) => {
        if (report) {
            if (status === "completed") {
                new Audio(yay).play()
                const jsConfetti = new JSConfetti()
                setTimeout(function () {
                    jsConfetti.addConfetti({
                        emojis: ['🧗', '📈', '🍛'],
                        emojiSize: 100,
                        confettiNumber: 100,
                    })
                }, 1000);
            }
            const temp: Email = {
                to: to,
                message: {
                    subject: `Report Request Update`,
                    html: renderToString(<EmailTemplate content={`<p>Hello,</p><p>The following report has been ${status}</p><p>${report.content}</p>`} />),
                }
            }
            await addDoc(collection(db, "email"), temp)
        }
    }

    const addRequestReport = async (report: ReportRequest) => {
        await setDoc(doc(db, "reports", report.id), report);
        sendRepCreateEmail(report)
        getReports()
    }

    const saveSow = async (sow: SOW) => {
        await setDoc(doc(db, "sow", sow.id), sow);
    }

    const updateReportStatus = async (type: "iainStatus" | "requestStatus" | "reportStatus", status: string, report: ReportRequest) => {
        const temp = report
        if (type === "iainStatus") {
            temp.status = status
            if (status === "Completed") {
                sendApprovedStatusEmail(report, "completed", [report.user.email])
            }
        }
        if (type === "reportStatus") {
            temp.ReportApproval = status
            if (status === "Denied") {
                sendApprovedStatusEmail(report, "denied", ["ibrown@eurosafeuk.com"])
            }
        }
        if (type === "requestStatus") {
            temp.RequestApproval = status
            if (status === "Approved") {
                sendApprovedStatusEmail(report, "approved", ["ibrown@eurosafeuk.com"])
            }
            if (status === "Denied") {
                sendApprovedStatusEmail(report, "denied", [report.user.email])
            }
        }
        await setDoc(doc(db, "reports", report.id), temp);
        getReports()
    }

    const addReportData = async (data: any[], report: ReportRequest) => {
        const temp = report
        temp.data = data
        await setDoc(doc(db, "reports", report.id), temp);
        getReports()
    }

    useEffect(() => {
        getProfile()
        getData()
        getAssetRegister()
        getProjects()
        getReports()
    }, [])

    useEffect(() => {
        getData()
        getProjects()
        getReports()
    }, [active])

    const user: User = { name: graphData?.displayName || "", email: graphData?.mail || "", id: graphData?.id || "" }

    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Page activeSub={activeSub} subMenu={viewSub} allData={allData} admins={admins} setActiveSub={(val) => setActiveSub(val)} user={user} active={active} setActive={(page) => setActive(page)}>
                {graphData &&
                    <>
                        {active === "my tickets" &&
                            <MyTickets
                                onSubChange={(ticket) => onSubChange(ticket)}
                                addNewComment={(ticket) => addComment(ticket)}
                                onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)}
                                onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)}
                                admins={admins}
                                user={graphData}
                                allData={allData.filter((item) => item.raisedBy.id === graphData.id && item.status !== "Completed" && item.status !== "Not Applicable")}
                            />
                        }
                        {active === "completed" &&
                            <Completed
                                onSubChange={(ticket) => onSubChange(ticket)}
                                addNewComment={(ticket) => addComment(ticket)}
                                onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)}
                                onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)}
                                admins={admins}
                                user={graphData}
                                allData={allData.filter((item) => item.raisedBy.id === graphData.id && (item.status === "Completed" || item.status === "No Response" || item.status === "Not Applicable"))}
                            />
                        }
                        {active === "raise ticket" &&
                            <RaiseTicket
                                addFile={(file) => uploadFile(file)}
                                raiseTicket={(ticket) => addTicket(ticket).then(() => getData())}
                                admins={admins}
                                user={user}
                                data={allData}
                            />
                        }
                        {active === "reports" &&
                            <Reports
                                assets={assets}
                                setReportData={(data, report) => addReportData(data, report)}
                                updateStatus={(type, status, report) => report && updateReportStatus(type, status, report)}
                                reportRequests={allReports}
                                createRequest={(request) => addRequestReport(request)}
                                admins={admins}
                                user={user}
                            />
                        }
                        {active === "open tickets" &&
                            <AllTickets
                                onSubChange={(ticket) => onSubChange(ticket)}
                                addNewComment={(ticket) => addComment(ticket)}
                                onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)}
                                onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)}
                                admins={admins}
                                user={graphData}
                                allData={allData.filter((item) => {
                                    const ids = ["Completed", "No Response", "Not Applicable"]
                                    if (!ids.includes(item.status)) {
                                        return item
                                    }
                                })}
                            />
                        }
                        {active === "asset register" &&
                            <AssetRegister
                                saveAsset={(asset) => setAssetRegister(asset)}
                                duplicateAsset={(asset) => duplicateAsset(asset)}
                                allData={assets}
                                admins={admins}
                                user={graphData}
                            />
                        }
                        {active === "developer" &&
                            <Developer
                            saveSow={(sow) => saveSow(sow)}
                                saveChange={(project) => setChangeProject(project)}
                                onTicketCreate={(ticket, project) => setNewProjectTicket(ticket, project)}
                                saveProject={(project) => setProject(project)}
                                allData={allProjects} />
                        }
                        {active === "dashboard" &&
                            <Dashboard
                                saveKnowledgeUpdates={(know) => addKnowledge(know, null)}
                                projects={allProjects}
                                newKnowledge={(item, file) => addKnowledge(item, file)}
                                knowledgebase={knowledgebase}
                                onSubChange={(ticket) => onSubChange(ticket)}
                                allData={allData}
                                page={activeSub}
                                addNewComment={(ticket) => addComment(ticket)}
                                onAssignedChange={(value, ticket) => onAssignedChange(value, ticket)}
                                onStatusPriorityChange={(type, value, ticket) => onStatusPriorityChange(type, value, ticket)}
                                admins={admins}
                                user={graphData}
                            />
                        }
                    </>
                }
            </Page>
        </>

    )
}

export default AuthenticatedHome