import { Link } from "react-router-dom"
import logo from "../Images/getsitelogo.png"
import { FaHouseUser, FaBell, FaUser, FaClipboardUser, FaIdCardClip, FaFileCircleExclamation, FaCircleCheck, FaCircleChevronLeft, FaCircleExclamation, FaTicket, FaFolderOpen, FaLaptopFile, FaChartPie, FaDoorOpen, FaFileLines, FaBrain, FaCode, FaChartLine, FaDatabase } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { User, ticket } from "../Pages/AuthenticatedHome";
import Dashboard from "../Pages/SubPages/Dashboard";
import Popover from '@mui/material/Popover';
import { MdMenu } from "react-icons/md";
import { Offcanvas } from "react-bootstrap";

interface Props {
    setActive: (page: string) => void
    setActiveSub: (page: string) => void
    active: string
    allData: ticket[]
    children: React.ReactNode
    user: User
    admins: User[]
    activeSub: string
    subMenu: boolean;
}



const Page = ({ setActive, active, children, user, setActiveSub, admins, allData, subMenu, activeSub = "Assigned Tickets" }: Props) => {
    const [dashboardPage, setDashboardPage] = useState<string>(activeSub)
    const [viewSub, setViewSub] = useState<boolean>(subMenu)
    const mobileMedia = window.matchMedia("(max-width: 480px)").matches
    const [show, setShow] = useState(false);
    const superAdmins = ["761b869d-2f90-4be7-91a6-3aaee6df11cd", "5b6600c9-e466-4f3f-8538-76da45fe82a8"]
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        setActiveSub(dashboardPage)
    }, [dashboardPage])

    useEffect(() => {
        setDashboardPage(activeSub)
    }, [activeSub])

    const getUnassigned = () => {
        let count = 0
        allData.map((ticket) => {
            if (ticket.assigned === undefined && ticket.status !== "Completed" && ticket.status !== "Not Applicable") {
                console.log(ticket)
                count = count + 1
            }
        })
        return count
    }

    const renderLinks = () => {
        return (<>
            {!viewSub ?
                <>
                    <a onClick={() => {
                        setDashboardPage("")
                        handleClose()
                        setActive("my tickets")
                    }} className={active === "my tickets" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaHouseUser color="white" size={35} />
                        My Tickets
                    </a>
                    <a onClick={() => {
                        setDashboardPage("")
                        handleClose()
                        setActive("completed")
                    }} className={active === "completed" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaCircleCheck color="white" size={35} />
                        Completed
                    </a>
                    <a onClick={() => {
                        setDashboardPage("")
                        handleClose()
                        setActive("raise ticket")
                    }} className={active === "raise ticket" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaTicket color="white" size={35} />
                        Raise Ticket
                    </a>
                    <a onClick={() => {
                        setDashboardPage("")
                        handleClose()
                        setActive("reports")
                    }} className={active === "reports" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaDatabase color="white" size={35} />
                        Reports
                    </a>
                    {admins.find((e) => e.id === user.id) &&
                        <>
                            <a onClick={() => {
                                setDashboardPage("")
                                handleClose()
                                setActive("open tickets")
                            }} className={active === "open tickets" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                                {getUnassigned() > 0 &&
                                    <div className={active === "open tickets" ? "page-nav-links__link--count page-nav-links__link--count--active" : "page-nav-links__link--count"}>
                                        <p>{getUnassigned()}</p>
                                    </div>
                                }
                                <FaFolderOpen color="white" size={35} />
                                Open Tickets
                            </a>
                            <a onClick={() => {
                                setDashboardPage("")
                                handleClose()
                                setActive("asset register")
                            }} className={active === "asset register" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                                <FaLaptopFile color="white" size={35} />
                                Asset Register
                            </a>
                            <a onClick={() => {
                                setDashboardPage("")
                                handleClose()
                                setActive("developer")
                            }} className={active === "developer" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                                <FaCode color="white" size={35} />
                                Developer
                            </a>
                            <a onClick={() => {
                                setActive("dashboard")
                                setDashboardPage("Assigned Tickets")
                                setViewSub(true)
                            }} className={active === "dashboard" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                                <FaChartPie color="white" size={35} />
                                Dashboard
                            </a>
                        </>
                    }
                </>
                :
                <>
                    {superAdmins.includes(user.id) &&
                        <a onClick={() => {
                            handleClose()
                            setDashboardPage("Reports")
                        }} className={dashboardPage === "Reports" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                            <FaChartLine color="white" size={35} />
                            Reports
                        </a>
                    }
                    <a onClick={() => {
                        setDashboardPage("Assigned Tickets")
                        handleClose()
                    }} className={dashboardPage === "Assigned Tickets" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaIdCardClip color="white" size={35} />
                        Assigned Tickets
                    </a>
                    <a onClick={() => {
                        handleClose()
                        setDashboardPage("High Priority")
                    }} className={dashboardPage === "High Priority" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaCircleExclamation color="white" size={35} />
                        High Priority
                    </a>
                    <a onClick={() => {
                        handleClose()
                        setDashboardPage("Completed")
                    }} className={dashboardPage === "Completed" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaCircleCheck color="white" size={35} />
                        Completed
                    </a>
                    <a onClick={() => {
                        handleClose()
                        setDashboardPage("1101 Notified")
                    }} className={dashboardPage === "1101 Notified" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaFileCircleExclamation color="white" size={35} />
                        1101 Notified
                    </a>
                    <a onClick={() => {
                        handleClose()
                        setDashboardPage("Assign Admin")
                    }} className={dashboardPage === "Assign Admin" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaClipboardUser color="white" size={35} />
                        Assign Admin
                    </a>
                    <a onClick={() => {
                        handleClose()
                        setDashboardPage("Knowledgebase")
                    }} className={dashboardPage === "Knowledgebase" ? "page-nav-links__link page-nav-links__link--active" : "page-nav-links__link"}>
                        <FaBrain color="white" size={35} />
                        Knowledgebase
                    </a>
                    <a onClick={() => {
                        setViewSub(false)
                    }} className="page-nav-links__link">
                        <FaCircleChevronLeft color="white" size={35} />
                        Main Menu
                    </a>
                </>
            }
        </>)
    }


    return (
        <div className="page">
            <div className="page-nav">
                <img className="page-nav-logo" src={logo} />
                <div className="page-nav-links">
                    {renderLinks()}
                </div>
                <div></div>
            </div>
            <div className="page-right">
                <div className="page-right-top">
                    <div className={`d-flex justify-content-center ${mobileMedia ? "flex-row align-items-center" : "flex-column"}`}>
                        {mobileMedia &&
                            <img className="page-nav-logo" src={logo} />
                        }
                        <h1 className="page-right-top__title m-0">{active.toLocaleUpperCase()}</h1>
                        {dashboardPage !== "" &&
                            <p className="m-0">{dashboardPage}</p>
                        }
                    </div>

                    <div className="d-flex flex-row align-items-center page-right-top-user">
                        <FaUser color="white" size={20} />
                        <p>{user.name}</p>
                    </div>

                    {mobileMedia &&
                        <MdMenu onClick={handleShow} color="white" size={40} />
                    }
                    {mobileMedia &&
                        <Offcanvas placement="end" show={show} onHide={handleClose}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Navigation</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div>
                                    {renderLinks()}
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    }
                </div>
                <div className="page-right-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Page